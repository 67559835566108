export default {
  data: () => ({
    bankDetail: {
      id: 0,
      bank_name: "",
      country: "",
      swift_code: "",
    },
    loading: false,
    bankNameList: [],
    backUrl: "/bank-account",
  }),
  methods: {
    resetForm() {
      this.$refs.observer.reset();
      this.bankDetail = {
        id: 0,
        bank_name: "",
        country: "",
        swift_code: "",
      };
    },
    validateBankDetail() {
      this.bankDetail.id > 0 ? this.update() : this.add();
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);
    },
    update() {
      // This function updates the bank account details
      let _vm = this;
      this.axios
        .post("/bank-accounts/" + this.bankDetail.id, {
          ...this.bankDetail,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.bankDetail = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    add() {
      let _vm = this;
      // Send a POST request to create a bank account
      this.axios
        .post("/bank-accounts", _vm.bankDetail)
        .then(function () {
          // Redirect to the specified URL after successful creation
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {
          // Handle any errors that occur during the request
        });
    },

    getBankName() {
      const _vm = this;
      // Make a GET request to fetch bank names
      this.axios
        .get("/bank-accounts-bank-names", _vm.bankNameList)
        .then(function (response) {
          _vm.bankNameList = response.data.data;
        })
        .catch(function () {
          // Handle any errors
        });
    },

    getDetail() {
      // Make a GET request to retrieve bank account details
      let _vm = this;
      this.axios
        .get("/bank-accounts/" + this.$route.params.id)
        .then(function (response) {
          _vm.bankDetail = response.data.data;
        })
        .catch(function () {});
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
    this.getBankName();

    jQuery("#account_number").keyup(function () {
      $(this).val($(this).val().toUpperCase());
    });
  },
};
